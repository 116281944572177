<template>
  <win-block>
    <template #win-title-left>Товары магазина</template>

    <v-card flat min-height="450px">

      <v-data-table
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-btn color="primary" dark class="mb-2 softvision"
                   @click="item_select=null; form_product=true">
              Добавить
            </v-btn>

            <v-dialog v-if="item_select" v-model="dialog_delete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Удалить {{ item_select.name }} ?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog_delete=false">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="itemDelete">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2"
                  @click="item_select=item; form_product=true">
            mdi-pencil
          </v-icon>
          <v-icon small @click="item_select=item; dialog_delete=true">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="initialize"
          >
            Reset
          </v-btn>
        </template>

      </v-data-table>

    </v-card>


    <form-product :show="form_product" :item="item_select" @saved="itemSaved($event)" @cancel="form_product=false"/>


  </win-block>
</template>

<script>
export default {
  components: {
    "form-product": () => import('./table-products-form-product'),
    "win-block": () => import('@/site/componets/winblock'),
  },
  data() {
    return {
      dialog_delete: false,
      item_select: null,
      form_product: false,
      loading: true,
      headers: [
        {text: 'Имя', value: 'name'},
        {text: 'Тип', value: 'type_text'},
        {text: 'Цена', value: 'price'},
        {text: 'Кол-во', value: 'quantity',},
        {text: 'Блокирован', value: 'block'},
        {text: '', value: 'actions', sortable: false},

      ],
      items: [],
      balance_global: 0,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.shop_project_products)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
    },
    itemSaved(item) {
      this.form_product=false
      if (this.item_select) {
        this.items = this.$lib.update(this.items, item)
      }
      if (!this.item_select) {
        this.items.unshift(item)
      }
    },
    itemDelete() {
      this.$axios.delete(this.$url.shop_project_products + `${this.item_select.id}/`)
          .then(response => {
            response
            let index = this.items.findIndex(obj => obj.id === this.item_select.id);
            this.items.splice(index, 1);
            this.dialog_delete = false
          })
    }
    ,
  }
}
</script>

